import './Logo.css';
import WebGLCircle from "./WebGLCircle";
import {useEffect, useState, useRef} from "react";

// const Logo = ({
//   title = "stringCode"
// }) => {
//
//     const refWrapper = useRef(null);
//     const [titleFontSize, setTitleFontSize] = useState('18 px');
//
//     useEffect(() => {
//         if (refWrapper.current !== null) {
//             const w = refWrapper.current.getBoundingClientRect().width;
//             const h = refWrapper.current.getBoundingClientRect().height;
//             console.log("I have been mounted", w, h)
//             const size = Math.min(w, h) * 0.08
//             setTitleFontSize(size + 'px')
//         }
//     }, []);
//
//     return (
//         <div className="Logo">
//             <WebGLCircle/>
//             <div className={'Logo-content-wrapper'} ref={refWrapper}>
//                 <h1 className={'Logo-title'} style={{fontSize: titleFontSize}}>{title}</h1>
//             </div>
//         </div>
//     )
// }
//
// export default Logo;

const Logo = ({
  title = "stringCode"
}) => {

    return (
        <div className="Logo">
            <WebGLCircle/>
            <div className={'Logo-content-wrapper'}>
                <h1 className={'Logo-title'}>{title}</h1>
            </div>
        </div>
    )
}

export default Logo;