import './App.css';
import Logo from "./components/Logo";

function App() {
  return (
      <div className="App">
          <header className="App-header">
              <Logo/>
          </header>
      </div>
  );
}

export default App;
